import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Page from "@src/components/Page";
import MenuList from "@src/components/OpenUpMenuList";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import Filters from "@openup/shared/components/Filters/Filters";
import InfiniteScrollingCards from "@openup/shared/components/Cards/InfiniteScrollingCards/InfiniteScrollingCards";
import {
  ContentEvent,
  ContentEventType,
  ContentItem,
  ContentTypePagination,
  useGetContents,
  useSaveContentEvent,
} from "@src/queries/contents";
import { useQueryThemesList } from "@src/queries/themes";
import { ReactElement, useEffect, useState } from "react";
import { getThemeIcon } from "@src/utils/getThemeIcon";
import { useTranslation } from "react-i18next";
import { withSuspense } from "@src/components/wrappers/Suspendable";
import { CONTENTS_TYPE_LIBRARY } from "@src/utils/constants";
import ArticleCard, { getLoadingCards } from "@src/components/ArticleCard";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { useNavigate } from "react-router-dom";

const CARDS_LOADING_AMOUNT = 9;

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
  },
  header: {
    marginBottom: theme.spacing(3),
    color: theme.colors.primaryDarkBlue,
    [theme.breakpoints.down("md")]: {
      lineHeight: "48px",
      fontSize: 35,
    },
    [theme.breakpoints.up("sm")]: {
      lineHeight: "64px",
    },
  },
  menuGrid: {
    backgroundColor: theme.colors.trueWhite,
    marginTop: theme.spacing(-8),
    marginBottom: theme.spacing(-3.75),
    marginRight: theme.spacing(6),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3.75),
    marginLeft: theme.spacing(-2),
    paddingLeft: theme.spacing(2),
  },
  welcomeText: {
    fontWeight: "400",
    lineHeight: "32px",
    letterSpacing: "0.5px",
    opacity: 0.7,
    color: theme.colors.primaryDarkBlue,
    marginBottom: theme.spacing(6),
  },
  boxWelcomeText: {
    margin: `${theme.spacing(0)} auto`,
  },
}));

interface Filter {
  id: string;
  name: string;
  icon: string;
}

const LibraryView = () => {
  const { t } = useTranslatedNavigate();
  const {
    i18n: { language },
  } = useTranslation();
  const isSmallScreen = useMediaQuery((x: any) => x.breakpoints.down("sm"));
  const classes = useStyles();
  const [filters, setFilters] = useState<Filter[] | undefined>();
  const [selectedThemeId, setSelectedThemeId] = useState<string | null>(null);
  const [articles, setArticles] = useState<ContentItem[]>();
  const [contentTypePagination, setContentTypePagination] = useState<
    ContentTypePagination[] | null
  >();
  const { pathT } = useTranslatedNavigate();
  const navigate = useNavigate();
  const [articleCards, setArticleCards] = useState<ReactElement[]>(
    getLoadingCards(CARDS_LOADING_AMOUNT),
  );
  const [isCardsLoading, setIsCardsLoading] = useState(true);
  const [loadingCardsAmount, setLoadingCardsAmount] = useState(0);

  const sendDataToGTM = useGTMDispatch();

  const {
    data: themes,
    isLoading: isLoadingThemes,
    isError: isErrorThemes,
  } = useQueryThemesList();

  const {
    data: articlesData,
    isLoading: isLoadingArticles,
    isError: isErrorArticles,
    fetchNextPage: fetchNextPageArticles,
    hasNextPage: hasNextPageArticles,
  } = useGetContents({
    language,
    type: CONTENTS_TYPE_LIBRARY,
    theme:
      themes?.find(({ ordinal }) => ordinal === selectedThemeId)?.key ?? null,
    limit: CARDS_LOADING_AMOUNT,
    contentTypePagination: contentTypePagination || null,
  });

  let cardsLeft = CARDS_LOADING_AMOUNT;
  let loadedItemsCount = 0;

  const totalItemsCount = articlesData?.pages?.[0]?.pagination?.total || 0;
  if (totalItemsCount && totalItemsCount !== 0) {
    loadedItemsCount = articlesData!.pages.reduce(
      (acc, page) => acc + page!.data.length,
      0,
    );
    cardsLeft = totalItemsCount - loadedItemsCount;
  }

  const { mutateAsync: saveEvent } = useSaveContentEvent();

  useEffect(() => {
    sendDataToGTM({
      event: "My OpenUp - Library Page - Articles Loaded",
      language,
      theme_id:
        themes?.find(({ ordinal }) => ordinal === selectedThemeId)?.key ||
        "All",
    });
  }, [language, selectedThemeId, sendDataToGTM, themes]);

  useEffect(() => {
    if (!articlesData || isLoadingArticles) {
      return;
    }

    setArticles(articlesData.pages?.map((page) => page!.data).flat() || []);
    setContentTypePagination(
      articlesData.pages?.length > 0
        ? articlesData.pages[articlesData.pages.length - 1]
            .contentTypePagination
        : null,
    );

    setIsCardsLoading(false);
  }, [articlesData, isLoadingArticles]);

  useEffect(() => {
    if (!themes || isLoadingThemes || isErrorThemes) {
      return;
    }
    setFilters([
      {
        id: null,
        name: t("ThemesFilter.All"),
        icon: "AppsOutlined",
      },
      ...themes.map(({ ordinal, key }) => ({
        id: ordinal,
        name: t(`SharedStrings.${key}`),
        icon: getThemeIcon(key),
      })),
    ]);
  }, [isErrorThemes, isLoadingThemes, t, themes]);

  useEffect(() => {
    if (!articles || isLoadingArticles || isErrorArticles) {
      return;
    }

    const attemptNavigationToCourse = (article: ContentItem) => {
      // courses do not have urls, only articles do.
      if (article.url === null || article.url === undefined) {
        navigate(`${pathT("route.course")}/${article.id}`);
      }
    };

    const articleClick = (article: ContentItem) => {
      attemptNavigationToCourse(article);

      const event: ContentEvent = {
        contentId: article.id,
        eventType: ContentEventType.Click,
      };
      sendDataToGTM({
        event: "My OpenUp - Library Page - Article Click",
        language,
        article_id: article.id,
      });
      saveEvent(event);
    };

    setArticleCards(
      articles.map((article) => (
        <ArticleCard
          className="w-full"
          key={article.id}
          article={article}
          isThemeVisible={!selectedThemeId}
          onMouseUp={(e) => {
            if (e.button === 0 || e.button === 1) {
              // left or middle button clicked
              articleClick(article);
            }
          }}
        />
      )),
    );
  }, [
    articles,
    isErrorArticles,
    isLoadingArticles,
    language,
    saveEvent,
    selectedThemeId,
    sendDataToGTM,
    navigate,
    pathT,
  ]);

  return (
    <Page title={t("LibraryView.Title")}>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container>
          <Hidden only={["xs", "sm"]}>
            <Grid item xs className={classes.menuGrid}>
              <MenuList menuItemStyle={{ color: "#ffffff" }} isOpen />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <Typography
              variant={isSmallScreen ? "h3" : "h1"}
              className={classes.header}
            >
              {t("LibraryView.Title")}
            </Typography>
            <Filters
              list={filters}
              selectedId={selectedThemeId}
              onClick={(id) => {
                setIsCardsLoading(true);
                setArticleCards([]);
                setArticles([]);
                setContentTypePagination(null);
                setLoadingCardsAmount(CARDS_LOADING_AMOUNT);
                setSelectedThemeId(
                  themes.find(({ ordinal }) => ordinal === id)?.ordinal ?? null,
                );
              }}
            />

            {!isLoadingArticles && isErrorArticles ? (
              <p className="h4 mt-4">
                {t("InvalidActionView.SomethingWentWrong")}
              </p>
            ) : (
              <InfiniteScrollingCards
                className="mt-8"
                cards={articleCards.concat(
                  isCardsLoading ? getLoadingCards(loadingCardsAmount) : [],
                )}
                isLoading={isCardsLoading}
                onBottomReached={async () => {
                  if (isCardsLoading && !hasNextPageArticles) {
                    return;
                  }
                  setLoadingCardsAmount(
                    cardsLeft > CARDS_LOADING_AMOUNT
                      ? CARDS_LOADING_AMOUNT
                      : cardsLeft,
                  );
                  sendDataToGTM({
                    event:
                      "My OpenUp - Library Page - Load More Articles Triggered",
                    language,
                  });
                  setIsCardsLoading(true);
                  await fetchNextPageArticles();
                  setIsCardsLoading(false);
                }}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

const mapStateToProps = (state) => state;

export default connect(mapStateToProps)(withSuspense(LibraryView));
