import { Container, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

const useStyles = makeStyles(({ colors, breakpoints, spacing }) => ({
  footerBar: {
    width: "100%",
    display: "flex",
    background: (isMedical) =>
      isMedical ? colors.tertiaryGreen : colors.primaryDarkBlue,
    [breakpoints.up("sm")]: {
      height: "80px",
      alignItems: "center",
    },
  },
  container: {
    display: "flex",
    height: "100%",
    paddingBottom: 0,
    color: colors.white,

    [breakpoints.up("sm")]: {
      alignItems: "center",
      justifyContent: "space-between",
    },
    [breakpoints.down("sm")]: {
      height: "240px",
      flexDirection: "column",
      flexWrap: "wrap",
      justifyContent: "space-evenly",
    },
  },
  links: {
    display: "flex",
    [breakpoints.down("sm")]: {
      flexDirection: "column",
      "& > :not(:last-child)": {
        marginBottom: spacing(2.5),
      },
    },
    [breakpoints.up("sm")]: {
      "& > :not(:last-child)": {
        marginRight: spacing(2.5),
      },
    },
  },
  icons: {
    display: "flex",
    flexDirection: "row",
    "& > :not(:last-child)": {
      marginRight: spacing(1.5),
    },
    [breakpoints.down("sm")]: {
      flexBasis: "100%",
      marginTop: spacing(2),
      justifyContent: "flex-end",
    },
  },
  commonFontProperties: {
    fontWeight: "500",
    color: colors.white,
  },
  commonTextProperties: {
    textDecorationLine: "underline",
    opacity: "0.7",
  },
  socialLink: {
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    border: `1px solid ${colors.white}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },
}));

const FooterBar = ({ isMedical }) => {
  const { t } = useTranslation();
  const classes = useStyles(isMedical);

  return (
    <div className={classes.footerBar}>
      <Container className={classes.container}>
        <Typography variant="body2" className={classes.commonFontProperties}>
          {t("Footbar.Text.Copyright")}
        </Typography>
        <div className={classes.links}>
          <Link
            href={t("Footer.Text.Link.TermsAndConditions")}
            target="_blank"
            variant="body2"
            className={clsx(
              classes.commonFontProperties,
              classes.commonTextProperties,
            )}
            underline="hover"
          >
            {t("Footbar.Text.TermsAndConditions")}
          </Link>
          <Link
            href={t("Footer.Text.Link.Privacy.Statement")}
            target="_blank"
            variant="body2"
            className={clsx(
              classes.commonFontProperties,
              classes.commonTextProperties,
            )}
            underline="hover"
          >
            {t("Footer.Text.Privacy.Statement")}
          </Link>
          <Link
            href={t("Footer.Text.Link.Cookies")}
            target="_blank"
            variant="body2"
            className={clsx(
              classes.commonFontProperties,
              classes.commonTextProperties,
            )}
            underline="hover"
          >
            {t("Footer.Text.Cookies")}
          </Link>
        </div>

        <div className={classes.icons}>
          <a
            href={t("Footer.Icons.Linkedin")}
            target="_blank"
            className={classes.socialLink}
            rel="noreferrer"
            aria-label="Linkedin"
          >
            <LinkedInIcon />
          </a>
          <a
            href={t("Footer.Icons.Instagram")}
            target="_blank"
            className={classes.socialLink}
            rel="noreferrer"
            aria-label="Instagram"
          >
            <InstagramIcon />
          </a>
        </div>
      </Container>
    </div>
  );
};

export default FooterBar;
