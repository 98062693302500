import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import BarChartIcon from "@mui/icons-material/BarChart";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ExternalLinkTile from "@src/components/ExternalLinkTile";
import ThemeBarChart from "@src/components/ThemeBarChart";
import ExpandableTypography from "@src/components/ExpandableText";
import Page from "@src/components/Page";
import ThemeCTA from "@src/components/ThemeCTA";
import useTranslatedNavigate, {
  convertThemeNameToThemeKey,
} from "@src/services/useTranslateNavigate";
import IllustrationWomen from "@src/resources/images/ilustation_women.svg";

import { displayLogic, TOP_SCORE_NUMBER } from "@src/utils/constants";
import clsx from "clsx";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { colors } from "@src/theme";
import { AllQuestionsModalContext } from "@src/layouts/LayoutThemePage";

const useClasses = makeStyles((theme) => ({
  image: ({ iconBackgroundColor }) => ({
    width: 40,
    height: 40,
    padding: 6,
    backgroundColor: iconBackgroundColor,
    borderRadius: 30,
  }),
  backgroundImage: {
    maxHeight: "355px",
    position: "absolute",
    zIndex: -1,
    right: -250,
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  checkinOverview: {
    padding: theme.spacing(4, 3),
    height: 436,
    [theme.breakpoints.down("sm")]: {
      height: 460,
    },
  },
  checkinOverviewTitle: {
    fontWeight: "bold",
    fontSize: 20,
    lineHeight: "24px",
    marginBottom: theme.spacing(1),
  },
  checkinOverviewIcon: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  checkinOverviewSubtitle: {
    marginBottom: theme.spacing(3),
  },
  checkInResults: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
  },
  getStarted: {
    marginBottom: theme.spacing(3),
  },
  gridBoxLeft: {
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(0),
    },
    marginBottom: theme.spacing(4),
    paddingRight: theme.spacing(3),
  },
  gridBoxRight: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(0),
    },
    marginBottom: theme.spacing(4),
    paddingLeft: theme.spacing(3),
  },
  focusPointsCard: {
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(0),
    },
    marginBottom: theme.spacing(6),
    paddingRight: theme.spacing(3),
  },
  scoreOverviewCard: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(0),
    },
    marginBottom: theme.spacing(6),
    paddingLeft: theme.spacing(3),
  },
  sliderStyles: {
    height: "324px",
    [theme.breakpoints.down("sm")]: {
      height: "352px",
    },
    backgroundColor: colors.primaryGreenTransparency,
    borderRadius: "8px",
    "& .slick-next": {
      color: colors.primaryDarkBlue,
      right: 0,
    },
    "& .slick-prev": {
      color: colors.primaryDarkBlue,
      left: 0,
      zIndex: 1,
    },
    "& .slick-track": {
      height: "324px",
      [theme.breakpoints.down("sm")]: {
        height: "352px",
      },
    },
    "& .slick-track>div": {
      height: "324px",
      [theme.breakpoints.down("sm")]: {
        height: "352px",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  titleTextSlideItem: {
    textAlign: "center",
    color: colors.tertiaryGreen,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(2.25),
  },
  descriptionTextSlideItem: {
    fontSize: "18px",
    opacity: 0.7,
    textAlign: "center",
    letterSpacing: "0.15px",
    color: colors.tertiaryGreen,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2.25),
  },
  externalLinksContainer: { position: "relative", marginTop: "32px" },
  scoreImage: {
    width: 296,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

export const FORM_NAME_THEME_MAP = {
  Stress: "stress-and-worry",
  Sleep: "sleep",
  Relationships: "social-relationships",
  Confidence: "self-confidence",
  Work: "work-performance",
  Purpose: "meaning",
  Health: "lifestyle",
  Mindfulness: "mindfulness",
};

const SlideItem = ({ titleText, descriptionText }) => {
  const classes = useClasses();
  return (
    <Box>
      <Typography variant="subtitle2" className={classes.titleTextSlideItem}>
        {titleText}
      </Typography>
      <Typography variant="h4" className={classes.descriptionTextSlideItem}>
        {descriptionText}
      </Typography>
    </Box>
  );
};

const ThemeView = ({
  themesSorted,
  lastCheckin,
  themeScore,
  firstThreeWorstQuestions,
  themeCheckins,
}) => {
  const { t } = useTranslatedNavigate();
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));
  const { themeName } = useParams();

  const [backgroundImage, setBackgroundImage] = useState("");

  const { toggleFocusModal } = useContext(AllQuestionsModalContext);

  const themeKey = convertThemeNameToThemeKey(themeName, t);

  useEffect(() => {
    setBackgroundImage(`/resources/images/${themeKey}.png`);
  }, [themeKey]);

  const { category, color: iconBackgroundColor } =
    displayLogic[lastCheckin?.isTrackedTheme ? lastCheckin.category : 0];

  const sliderSettings = {
    infinite: true,
    touchMove: true,
    slidesToShow: 1,
    nextArrow: <NavigateNextIcon />,
    prevArrow: <NavigateBeforeIcon />,
  };

  const classes = useClasses({ iconBackgroundColor });

  return (
    <Page
      id="ThemeViewInsights"
      title={t("ThemeViewInsights.PageTitle", {
        themeName: t(`SharedStrings.${themeKey}`),
      })}
    >
      <Grid container>
        <Typography variant="h2">
          {" "}
          {t(`ThemeViewInsights.${themeKey}.PageHeader.${category}`)}
        </Typography>
        <ExpandableTypography
          text={t(`ThemeViewInsights.${themeKey}.MainFeedbackText.${category}`)}
          variant={isSmallScreen ? "h6" : "body1"}
        />
        <Typography variant="h3" className={classes.checkInResults}>
          {t("ThemeViewInsights.Checkin.Results")}
        </Typography>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className={classes.focusPointsCard}
          >
            <Paper className={classes.checkinOverview} elevation={15}>
              <div>
                <Box display="flex" alignItems="center" mb={2}>
                  <ThumbUpAltOutlinedIcon
                    className={clsx(
                      classes.checkinOverviewIcon,
                      "material-icon-outlined",
                    )}
                  />
                  <Typography
                    variant="body1"
                    className={classes.checkinOverviewTitle}
                  >
                    {t(
                      `ThemeViewInsights.${themeKey}.TipsForImprovement.${category}`,
                    )}
                  </Typography>
                </Box>
                <Box>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <Slider {...sliderSettings} className={classes.sliderStyles}>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {!themeCheckins.isTrackedTheme ? (
                      <SlideItem
                        descriptionText={t(
                          `ThemeViewInsights.${themeKey}.DescriptionFirstCheckIn`,
                        )}
                      />
                    ) : themeScore[themeScore.length - 1] &&
                      firstThreeWorstQuestions &&
                      themeScore[themeScore.length - 1] !== TOP_SCORE_NUMBER ? (
                      firstThreeWorstQuestions.map((element) => (
                        <SlideItem
                          titleText={element.goal}
                          descriptionText={element.tip}
                        />
                      ))
                    ) : (
                      <SlideItem
                        titleText={t(
                          `ThemeViewInsights.${themeKey}.TitleTextImprovementTopScore`,
                        )}
                        descriptionText={t(
                          `ThemeViewInsights.${themeKey}.DescriptionTextImprovementTopScore1`,
                        )}
                      />
                    )}
                  </Slider>
                </Box>
              </div>
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className={classes.scoreOverviewCard}
          >
            <Paper className={classes.checkinOverview} elevation={15}>
              <Box display="flex" alignItems="center" mb={2}>
                <BarChartIcon className={classes.checkinOverviewIcon} />
                <Typography
                  variant="body1"
                  className={classes.checkinOverviewTitle}
                >
                  {t(`ThemeViewInsights.${themeKey}.ChartTitle.${category}`)}
                </Typography>
              </Box>
              {themeCheckins.isTrackedTheme ? (
                <>
                  <Typography
                    variant="h6"
                    className={classes.checkinOverviewSubtitle}
                  >
                    {t(
                      `ThemeViewInsights.${themeKey}.ChartDescription.${category}`,
                    )}
                  </Typography>
                  <ThemeBarChart
                    data={themesSorted}
                    centerContent
                    labelPosition="right"
                    setLabelPositionToRight
                    width={200}
                    barSpacingWidth={25}
                    xAxisScrollable
                    xAxisLabelHeight={90}
                    onBarClick={toggleFocusModal}
                    maxBarsDisplayed={3}
                  />
                </>
              ) : (
                <>
                  <Typography
                    variant="h6"
                    className={classes.checkinOverviewSubtitle}
                  >
                    {t(
                      `ThemeViewInsights.${themeKey}.ChartDescription.${category}`,
                    )}
                  </Typography>
                  <img
                    alt="Illustration Women"
                    className={classes.scoreImage}
                    src={IllustrationWomen}
                  />
                </>
              )}
            </Paper>
          </Grid>
        </Grid>
        <ThemeCTA themeKey={themeKey} />
        <box>
          <Typography variant="h3" className={classes.getStarted}>
            {t("ThemeViewInsights.Get.Started")}
          </Typography>
          <ExpandableTypography
            text={t(`ThemeViewInsights.${themeKey}.GetStartedText`)}
            variant={isSmallScreen ? "h6" : "body1"}
          />
        </box>
        <Grid container className={classes.externalLinksContainer}>
          <img
            className={classes.backgroundImage}
            src={backgroundImage}
            alt={t("ThemeViewInsights.BackgroundImageAlt")}
          />
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.gridBoxLeft}
            >
              <Box mb={2}>
                <ExternalLinkTile
                  titleText={t(
                    `ThemeViewInsights.${themeKey}.Tile1.Title.${category}`,
                  )}
                  bodyText={t(
                    `ThemeViewInsights.${themeKey}.Tile1.Description.${category}`,
                  )}
                  iconLigature={t(
                    `ThemeViewInsights.${themeKey}.Tile1.LeftIcon.${category}`,
                  )}
                  url={t(
                    `ThemeViewInsights.${themeKey}.Tile1.Link.${category}`,
                  )}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.gridBoxRight}
            >
              <Box mb={2}>
                <ExternalLinkTile
                  titleText={t(
                    `ThemeViewInsights.${themeKey}.Tile2.Title.${category}`,
                  )}
                  bodyText={t(
                    `ThemeViewInsights.${themeKey}.Tile2.Description.${category}`,
                  )}
                  iconLigature={t(
                    `ThemeViewInsights.${themeKey}.Tile2.LeftIcon.${category}`,
                  )}
                  url={t(
                    `ThemeViewInsights.${themeKey}.Tile2.Link.${category}`,
                  )}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.gridBoxLeft}
            >
              <Box mb={2}>
                <ExternalLinkTile
                  titleText={t(
                    `ThemeViewInsights.${themeKey}.Tile3.Title.${category}`,
                  )}
                  bodyText={t(
                    `ThemeViewInsights.${themeKey}.Tile3.Description.${category}`,
                  )}
                  iconLigature={t(
                    `ThemeViewInsights.${themeKey}.Tile3.LeftIcon.${category}`,
                  )}
                  url={t(
                    `ThemeViewInsights.${themeKey}.Tile3.Link.${category}`,
                  )}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};

export default ThemeView;
