import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import colors from "@src/theme/colors";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";

const useClasses = makeStyles(({ breakpoints, spacing }) => ({
  container: {
    backgroundColor: colors.highlightedBackground,
    borderRadius: 20,
    padding: spacing(2, 3),
    marginTop: spacing(5),
    marginBottom: spacing(5),
    [breakpoints.down("md")]: {
      padding: spacing(3),
    },
  },
  text: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  button: {
    fontSize: "14px",
    [breakpoints.down("md")]: {
      width: "100%",
      marginTop: spacing(3),
    },
  },
}));

const ThemeCTA = ({ themeKey }) => {
  const classes = useClasses();
  const { t } = useTranslatedNavigate();

  const navigate = useNavigate();

  const navigateThemeAction = () => {
    const themeRoute = t("route.themes", {
      key: t(`route.ThemeKey.${themeKey}`),
    });
    navigate(`/${themeRoute}/${t("route.checkin")}`);
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      className={classes.container}
    >
      <Typography variant="h6" className={classes.text}>
        <b>
          {t("ThemeViewInsights.ChartNewCheckIn", {
            key: t(`SharedStrings.Chart.${themeKey}`),
          })}
        </b>
      </Typography>

      <Button
        className={classes.button}
        size="large"
        type="submit"
        variant="contained"
        onClick={navigateThemeAction}
      >
        {t("DashboardView.Chart.CheckinButton")}
      </Button>
    </Grid>
  );
};

export default ThemeCTA;
