import {
  AzureCommunicationTokenCredential,
  CommunicationUserIdentifier,
} from "@azure/communication-common";
import {
  fromFlatCommunicationIdentifier,
  CallAndChatLocator,
  useAzureCommunicationCallWithChatAdapter,
  CallWithChatComposite,
  COMPOSITE_LOCALE_FR_FR,
  COMPOSITE_LOCALE_ES_ES,
  COMPOSITE_LOCALE_EN_GB,
  COMPOSITE_LOCALE_NL_NL,
  COMPOSITE_LOCALE_DE_DE,
} from "@azure/communication-react";
import i18n from "@src/i18n";
import { VideoCallingToken } from "@src/models/VideoCallingToken";
import { useConsultQuery } from "@src/queries/booking";
import { getConsultLiveStatus } from "@src/queries/consults";
import {
  getToken,
  isExpertInCall as isExpertIncallApi,
} from "@src/queries/video-calling";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { RESPONSE_STATUS, STATUS } from "@src/utils/constants";
import NotFoundView from "@src/views/errors/NotFoundView";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as WomanPillow } from "@openup/shared/resources/images/woman_pillow.svg";
import { getAcsFileUploadHandler } from "@src/utils/acsFileUploadHandler";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { initializeIcons } from "@uifabric/icons";
import { getApiBaseUrl } from "@src/utils/urlHelpers";

const getAcsLocale = () => {
  const { language } = i18n;
  switch (language) {
    case "en-GB":
      return COMPOSITE_LOCALE_EN_GB;
    case "es-ES":
      return COMPOSITE_LOCALE_ES_ES;
    case "fr-FR":
      return COMPOSITE_LOCALE_FR_FR;
    case "nl-NL":
      return COMPOSITE_LOCALE_NL_NL;
    case "de-DE":
      return COMPOSITE_LOCALE_DE_DE;
    default:
      return COMPOSITE_LOCALE_EN_GB;
  }
};
const isMobile = () =>
  window.matchMedia && window.matchMedia("(max-width: 640px)").matches;

initializeIcons();

const MakeCall: React.FC = () => {
  const { consultId } = useParams();
  const navigate = useNavigate();

  const appInsights = useAppInsightsContext();

  const [token, setToken] = useState<VideoCallingToken | null>();
  const [roomId, setRoomId] = useState("");
  const [chatThreadId, setChatThreadId] = useState("");
  const [isExpertInCall, setIsExpertInCall] = useState(false);

  const { data: consult } = useConsultQuery(consultId);
  const { t, pathT } = useTranslatedNavigate();

  useEffect(() => {
    (async () => {
      const logonDetails = await getToken(consult.roomId).catch((e) => {
        if (e.response.status === RESPONSE_STATUS.BAD_REQUEST) {
          return null;
        }
        return undefined;
      });

      setRoomId(consult.roomId);
      setChatThreadId(consult.chatThreadId);
      setToken(logonDetails);
    })();
  }, [consult.roomId, consult.chatThreadId]);
  useEffect(() => {
    const intervalCall = setInterval(async () => {
      await isExpertIncallApi(consult.roomId).then((response) => {
        const expertIncall = response === true;
        setIsExpertInCall(expertIncall);
        if (expertIncall) {
          clearInterval(intervalCall);
        }
        return response;
      });
    }, 5000);
  }, [consult.roomId]);

  // A well-formed token is required to initialize the chat and calling adapters.
  const credential = useMemo(() => {
    if (!token) return undefined;
    try {
      return new AzureCommunicationTokenCredential(token.token);
    } catch (err) {
      appInsights.trackException(err);
      return null;
    }
  }, [appInsights, token]);

  // Memoize arguments to `useAzureCommunicationCallAdapter` so that
  // a new adapter is only created when an argument changes.
  const callAdapterArgs = useMemo(
    () => ({
      userId: token
        ? (fromFlatCommunicationIdentifier(
            token?.communicationUserId,
          ) as CommunicationUserIdentifier)
        : undefined,
      displayName: token?.firstName,
      credential,
      endpoint: token?.endpointUrl,
      locator: {
        callLocator: {
          roomId,
        },
        chatThreadId,
      } as unknown as CallAndChatLocator,
    }),
    [token, credential, roomId, chatThreadId],
  );

  const callAdapter = useAzureCommunicationCallWithChatAdapter(callAdapterArgs);
  callAdapter?.unmute();
  callAdapter?.startCamera();
  callAdapter?.onStateChange(async (state) => {
    if (state.page === "leaving") {
      const consultStatus = await getConsultLiveStatus(consult.id);
      if (consultStatus === STATUS.COMPLETED) {
        navigate(
          pathT("route.mySessions.review").replace(":consultId", consult.id),
        );
      } else {
        navigate(pathT("route.mySessions"));
      }
    }
  });

  if (token === null || consult?.canJoinVideoCall === false)
    return <NotFoundView />;

  return (
    <div className="h-full flex">
      <div className="flex-auto w-full">
        {callAdapter &&
          (isExpertInCall ? (
            <CallWithChatComposite
              adapter={callAdapter}
              locale={getAcsLocale()}
              formFactor={isMobile() ? "mobile" : "desktop"}
              options={{
                fileSharing: {
                  uploadHandler: getAcsFileUploadHandler(
                    chatThreadId,
                    t("MakeCall.UploadFailed"),
                  ),
                  downloadHandler: async (userId, fileData) => {
                    return new URL(fileData.url, getApiBaseUrl());
                  },
                  accept:
                    '"application/pdf", "image/*", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"',
                  multiple: true,
                },
              }}
            />
          ) : (
            <div className="flex justify-center items-center p-4 md:p-8">
              <div className="max-w-[500px] p-6 md:p-12 bg-white gap-6 rounded-2xl shadow-xl flex flex-col text-center">
                <WomanPillow className="self-center" />
                <p className="text-indigo-800 text-lg font-medium tracking-wide m-0">
                  {t("MakeCall.WaitingForExpert")}
                </p>
              </div>
            </div>
          ))}
        {token && credential === null && (
          <h3>Failed to construct credential. Provided token is malformed.</h3>
        )}
        {(token === undefined || !consult || !callAdapter || !credential) && (
          <h3 className="p-3 text-white">{t("MakeCall.Initializing")}</h3>
        )}
      </div>
    </div>
  );
};

export default MakeCall;
