import axios from "axios";
import { useMutation, useQuery } from "react-query";
import {
  apiPaths,
  spaceCreationErrors,
  RESPONSE_STATUS,
} from "@src/utils/constants";
import { consultStaleTime, hours, minutes } from "@src/queries/staletimes";
import { Space } from "@openup/shared/models/Space";

const identifier = "spaces";

/// This is the query that is used to get the space that the user is trying to confirm
/// params: email, token
/// returns: space
export const useGetUnconfirmedSpace = ({
  email,
  token,
  onExpiredData,
  onExternalError,
}) =>
  useQuery(
    [apiPaths.confirmSpace, email, token],
    async () => {
      try {
        const { data } = await axios.post<Space>(
          `${apiPaths.confirmSpace}?token=${token}`,
          {
            email,
          },
        );
        return data;
      } catch (error) {
        if (
          error?.response &&
          error?.response?.status === RESPONSE_STATUS.NOT_FOUND
        ) {
          return onExpiredData();
        }
        if (
          error?.response &&
          error?.response?.status === RESPONSE_STATUS.BAD_REQUEST
        ) {
          if (
            error?.response?.data === spaceCreationErrors.SPACE_USER_EXPIRED
          ) {
            return onExpiredData();
          }
          if (
            error?.response?.data ===
            spaceCreationErrors.SPACE_USER_REGISTRATION_FAILED
          ) {
            return onExternalError();
          }
          onExternalError();
        }
        if (error?.response?.status === 404) {
          return onExternalError();
        }
        return null;
      }
    },
    {
      onError: () => {
        onExternalError();
      },
      enabled: false,
      staleTime: minutes(consultStaleTime),
    },
  );

export const useGetClientSpace = (spaceId: string, onError: Function) => {
  return useQuery(
    [spaceId, identifier],
    async () => {
      try {
        const { data } = await axios.get<Space>(`/${identifier}/${spaceId}`);
        return data;
      } catch (error) {
        return onError();
      }
    },
    {
      staleTime: hours(1),
    },
  );
};

export const useCancelClientSpaceRegistration = (
  spaceId: string,
  onSuccess: Function,
  onError: Function,
) => {
  const { mutateAsync: cancelSpace } = useMutation<
    () => Promise<void>,
    Error,
    string
  >(
    async () => {
      try {
        await axios.delete(`${identifier}/${spaceId}`);
        return onSuccess();
      } catch (error) {
        return onError();
      }
    },
    {
      onError: () => {
        onError();
      },
    },
  );

  return {
    cancelSpace,
  };
};
