import { Box, Typography, useMediaQuery, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TypographyWithIcon from "@src/components/TypographyWithIcon";
import { ReactComponent as ManFitness } from "@src/resources/images/man_fitness.svg";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment-timezone";
import { formatSessionType, formatTime } from "@src/utils/formatting";
import CenteredLoader from "@src/components/CenteredLoader";
import { Icon } from "@src/components/Icon";
import AddToCalendar from "@openup/shared/components/AddToCalendar/AddToCalendar";
import { createAndDownloadICSEvent } from "@src/utils/helpers";
import { ReactComponent as GoogleIcon } from "@openup/shared/resources/icons/google.svg";
import { ReactComponent as MicrosoftIcon } from "@openup/shared/resources/icons/microsoft.svg";
import { ReactComponent as AppleIcon } from "@openup/shared/resources/icons/apple.svg";
import { getMySessionsUrl } from "@src/utils/urlHelpers";
import BookingContainer from "./BookingContainer";
import BookingCard from "./BookingCard";

const useStyles = makeStyles((theme) => ({
  bookingDetailsMessage: {
    letterSpacing: "0.15px",
    opacity: 1,
    fontWeight: 500,
  },
  confirmationEmailMessage: {
    maxWidth: 240,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 296,
    },
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3.5),
    opacity: 0.7,
    fontWeight: "normal",
    letterSpacing: "0.5px",
  },
  image: {
    float: "right",
    marginTop: theme.spacing(10.5),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0),
      position: "relative",
      left: theme.spacing(2.5),
      width: 152,
      height: 152,
    },
  },
  box: {
    maxWidth: 240,
    marginBottom: theme.spacing(2),
  },
  boxCalendarWithIcon: {
    maxWidth: 240,
  },
  typographyBack: {
    color: theme.colors.primaryDarkBlue,
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "1.25px",
    textDecoration: "underline",
  },
  typographyCalendar: {
    fontWeight: 500,
    color: "white",
    letterSpacing: "1.25px",
    opacity: 1,
  },
  iconCalendar: {
    fontSize: "20px",
    marginRight: theme.spacing(2),
  },
  linkActions: {
    marginRight: theme.spacing(1),
  },
  boxFooter: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  addToCalendar: {
    color: theme.colors.trueWhite,
    "&:hover": {
      textDecoration: "none",
    },
  },
  confirmedIcon: {
    fontSize: theme.spacing(3),
    color: theme.colors.primaryDarkBlue,
    marginRight: theme.spacing(2),
  },
}));

const BookingConfirmation = ({ consult }) => {
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));
  const classes = useStyles();
  const { t, pathT } = useTranslatedNavigate();

  if (!consult) {
    return <CenteredLoader />;
  }

  const addToCalendarItems = [
    {
      title: t("Booking.ConfirmationPage.AddToGoogleCalendar"),
      url: t("Booking.ConfirmationPage.AddToGoogleCalendarLink", {
        sessionType: formatSessionType(consult.consultType),
        linkToMySessions: decodeURIComponent(getMySessionsUrl()),
        startDate: formatTime(consult.startedAt),
        endDate: formatTime(
          moment(consult.startedAt)
            .add(30, "minutes")
            .utc()
            .format("YYYY-MM-DDTHH-mm-ss[Z]"),
        ),
      }),
      icon: <GoogleIcon width={24} height={24} />,
    },
    {
      title: t("Booking.ConfirmationPage.AddToMicrosoftCalendar"),
      url: t("Booking.ConfirmationPage.AddToMicrosoftCalendarLink", {
        sessionType: formatSessionType(consult.consultType),
        linkToMySessions: getMySessionsUrl(),
        startDate: encodeURIComponent(
          moment(consult.startedAt)
            .utc()
            .format("YYYY-MM-DD[T]HH:mm:ss[+00:00]"),
        ),
        endDate: encodeURIComponent(
          moment(consult.startedAt)
            .add(30, "minutes")
            .utc()
            .format("YYYY-MM-DD[T]HH:mm:ss+00:00"),
        ),
      }),
      icon: <MicrosoftIcon width={24} height={24} />,
    },
    {
      title: t("Booking.ConfirmationPage.AddToAppleCalendar"),
      onClick: async (clickEvent) => {
        clickEvent.preventDefault();

        await createAndDownloadICSEvent({
          title: t("Booking.ConfirmationPage.AddToAppleCalendarTitle", {
            sessionType: formatSessionType(consult.consultType),
          }),
          url: getMySessionsUrl(),
          description: t(
            "Booking.ConfirmationPage.AddToAppleCalendarDescription",
          ),
          location: getMySessionsUrl(),
          start: consult.startedAt,
          durationMinutes: 30,
          filenamePrefix: "openup",
        });
      },
      icon: <AppleIcon width={24} height={24} />,
    },
  ];

  return (
    <BookingContainer
      title={t("Booking.ConfirmationPage.Title")}
      className="pb-[80px]"
    >
      <BookingCard
        image={<ManFitness className={classes.image} />}
        noImageGap
        actions={
          <Box className={classes.boxFooter}>
            <Link
              component={RouterLink}
              to={pathT("route.login")}
              className={classes.linkActions}
              onClick={() => {
                window.OpenUpMobile?.closeWebView();
                window.webkit?.messageHandlers?.OpenUpMobile?.postMessage(
                  "closeWebview",
                );
              }}
              underline="hover"
            >
              <Typography className={classes.typographyBack}>
                {isSmallScreen
                  ? t("Booking.ConfirmationPage.ToMyOpenUp")
                  : t("Booking.ConfirmationPage.BackToMyOpenUp")}
              </Typography>
            </Link>
            <AddToCalendar
              buttonText={t("Booking.ConfirmationPage.AddToCalendar")}
              items={addToCalendarItems}
              className="w-full"
            />
          </Box>
        }
      >
        <Typography
          variant="subtitle1"
          className={classes.bookingDetailsMessage}
        >
          {t("Booking.ConfirmationPage.Message1")}
        </Typography>
        <Typography
          variant="subtitle2"
          className={classes.confirmationEmailMessage}
        >
          {`${t("Booking.ConfirmationPage.Message2")} ${
            (consult?.clientEmail || consult?.user?.email) ?? ""
          }`}
        </Typography>
        <div className="flex mb-4 items-center text-indigo-800 opacity-70 gap-4">
          <Icon iconKey={consult.consultType} />
          <p className="text-sm">
            {t(`Booking.TimeSlot.SessionType.${consult.consultType}`)}
          </p>
        </div>
        <TypographyWithIcon
          variant="body2"
          classesIcon={classes.confirmedIcon}
          iconLigature="flag"
          outlined
          boxClass={classes.box}
        >
          {t("Booking.TimeSlot.Language", {
            key: t(`SessionLanguage.${consult.consultLanguage}`),
          })}
        </TypographyWithIcon>
        <TypographyWithIcon
          variant="body2"
          classesIcon={classes.confirmedIcon}
          iconLigature="psychology"
          outlined
          boxClass={classes.box}
        >
          {consult.employeeFullName}
        </TypographyWithIcon>
        <TypographyWithIcon
          variant="body2"
          classesIcon={classes.confirmedIcon}
          iconLigature="event_available"
          outlined
          boxClass={classes.boxCalendarWithIcon}
        >
          {moment(consult.startedAt)
            .tz(consult.clientTimezone)
            ?.format("ddd D MMM YYYY @ H:mm")}
        </TypographyWithIcon>
      </BookingCard>
    </BookingContainer>
  );
};

export default BookingConfirmation;
