import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, TextField, useMediaQuery } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import LanguageSelector from "@src/components/LanguageSelector";
import Logo from "@src/components/Logo";
import { useNavigate } from "react-router-dom";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { ErrorMessage, Formik } from "formik";
import Button from "@openup/shared/components/Button/Button";
import { getValidationSchema } from "@src/views/auth/SingleSignOnView/ssoValidationHelper";
import WizardContainer from "@src/views/onboarding/components/WizardContainer";
import {
  guestUserLoginActions,
  ONBOARDING_VIEW_REFERRAL_STATUS,
  timeSnackbar,
} from "@src/utils/constants";
import { signUpExternalUserCodeFlow } from "@src/actions/userActions";
import { withSnackbar } from "@src/components/SnackBarComponent";
import { connect } from "react-redux";
import sessionStorageService from "@src/services/sessionStorage.service";
import Loader from "@src/components/Loader";
import { OidcClient } from "oidc-client-ts";
import AuthService from "@src/services/auth.service";
import CenteredLoader from "@src/components/CenteredLoader";
import { getSsoSettings } from "@src/views/auth/SingleSignOnView/ssoProviderHelper";

interface TopBarProps {
  isBackButton?: boolean;
  navigateBack: () => void;
}

// [TODO] move to shared component
const TopBar: FC<TopBarProps> = ({
  isBackButton = true,
  navigateBack = () => {},
}) => {
  const isSmallScreen = useMediaQuery((x: any) => x.breakpoints.down("md"));

  return (
    <div className="w-full flex justify-between items-center pt-6 md:pt-12 pb-16 md:pb-8 px-4">
      {isBackButton ? (
        <div className="flex items-center w-1/3">
          <button
            type="button"
            onClick={navigateBack}
            className="flex items-center justify-center rounded-full border-none h-[48px] w-[48px] bg-orange-100 hover:cursor-pointer text-indigo-800"
            aria-label="Go to previous page"
          >
            <ChevronLeftIcon
              className={`${isSmallScreen ? "scale-110" : ""}`}
            />
          </button>
        </div>
      ) : (
        <div className="w-1/3" />
      )}
      <div className="flex items-center justify-center w-1/3">
        <Logo className="scale-125" />
      </div>
      <div className="flex justify-end w-1/3">
        <div className="rounded-3xl">
          <LanguageSelector
            classNameSelect="bg-orange-100"
            classNameDropdown="bg-orange-100"
          />
        </div>
      </div>
    </div>
  );
};

interface SingleSignOnEmailViewProps {
  authReducers: any;
  signUpExternalUserCodeFlow: (code: string, provider: string) => Promise<any>;
  snackbarShowMessage: (message: string, variant?: string) => void;
  hideUI: boolean;
  onSuccessfulSso?: () => void;
  onSsoFailure?: () => void;
}

const SingleSignOnEmailView: FC<SingleSignOnEmailViewProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-shadow
  signUpExternalUserCodeFlow,
  snackbarShowMessage,
  authReducers,
  hideUI = false,
  onSuccessfulSso = () => {},
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  onSsoFailure = () => {},
}) => {
  const { t, pathT } = useTranslatedNavigate();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((x: any) => x.breakpoints.down("md"));

  const [externalPopup, setExternalPopup] = useState<Window | null>(null);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [provider, setProviderName] = useState("");
  const urlParams = new URLSearchParams(window.location.search);
  const isSignUpForm = urlParams.get("IsSignUp") === "true";
  const isOnboarding = sessionStorageService.getIsUserHasOnboarding();
  const authOnLoading = sessionStorageService.hasSSOFlowInStorage();

  useEffect(() => {
    if (!authReducers.twoFactorPending || authReducers.isLoggedIn) {
      return;
    }

    if (isOnboarding) {
      navigate(
        `${pathT("route.twoFactorCodeRequest")}?action=${
          guestUserLoginActions.explicitConsent
        }`,
        {
          replace: true,
        },
      );
    } else {
      navigate(pathT("route.twoFactorCodeRequest"), {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [authReducers.twoFactorPending]);

  const onNavigationBack = () => {
    if (isOnboarding) {
      navigate(pathT("route.welcome-onboarding"));
    } else if (isSignUpForm) {
      navigate(pathT("route.register"));
    } else {
      navigate(pathT("route.login"));
    }
  };

  const handleAuthResponse = async (res) => {
    try {
      setIsLoading(true);
      const response = await signUpExternalUserCodeFlow(res.code, provider);

      snackbarShowMessage(response.message, "success");
      if (response?.twoFactorVerification === "pending") {
        return;
      }
      setTimeout(() => {
        if (hideUI) {
          onSuccessfulSso();
          return;
        }
        if (response.isRegistered) {
          navigate(pathT("route.account"), { replace: true });
        } else if (isOnboarding) {
          navigate(pathT("route.register.explicit-consent"), {
            replace: true,
          });
        } else {
          sessionStorageService.saveSSOFlowProviderToStorage(provider);

          navigate(pathT("route.register"), { replace: true });
        }
      }, timeSnackbar);
    } catch (error) {
      setIsLoading(false);
      snackbarShowMessage(t("InvalidActionView.SomethingWentWrong"));
    }
  };

  useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const interval = setInterval(() => {
      if (externalPopup.closed) {
        clearInterval(interval);
        setExternalPopup(null);
        setProviderName("");
        return;
      }

      try {
        const urlExternalParams = new URLSearchParams(
          externalPopup.location.search,
        );
        if (urlExternalParams.get("code")) {
          const code = urlExternalParams.get("code");
          externalPopup.close();
          handleAuthResponse({ code });
        } else if (urlExternalParams.get("error")) {
          externalPopup.close();
        }
      } catch (error) {
        // eslint-disable-next-line no-console
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalPopup]);

  const authProvider = async () => {
    const ssoSettings = getSsoSettings(provider);

    if (!ssoSettings) {
      snackbarShowMessage(t("InvalidActionView.SomethingWentWrong"));
    } else {
      const oidcClient = new OidcClient(ssoSettings);
      const signinRequest = await oidcClient.createSigninRequest({
        state: { bar: 15 },
      });
      const width = 500;
      const height = 400;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2.5;
      const title = `WINDOW TITLE`;
      const { url } = signinRequest;
      const popup = window.open(
        url,
        title,
        `width=${width},height=${height},left=${left},top=${top}`,
      );
      setExternalPopup(popup);
    }
  };

  useEffect(() => {
    if (!provider) {
      return;
    }
    authProvider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  useEffect(() => {
    if (authOnLoading) {
      try {
        const providerName =
          sessionStorageService.getSSOFlowProviderFromStorage() as string;
        setProviderName(providerName);
      } catch (error) {
        // console.log(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (value, validateForm, setFieldError) => {
    setIsInvalidEmail(false);
    const errors = await validateForm();

    if (!errors.email) {
      try {
        const employerSSO = await AuthService.getEmployerSso({ email: value });

        if (!employerSSO.ssoSupported) {
          setFieldError("email", t("SingleSignOn.View.NotSupportedError"));
        } else {
          sessionStorageService.saveReferralToStorage({
            referral: ONBOARDING_VIEW_REFERRAL_STATUS.COMPANY,
            companyId: employerSSO.employerId,
          });

          setProviderName(employerSSO.provider);
        }
      } catch (error) {
        setFieldError("email", t("SingleSignOn.View.NotSupportedError"));
      }
    }
  };

  // this option is to only trigger the SSO popup, and then redirect to another screen
  if (hideUI) {
    return <CenteredLoader />;
  }

  return (
    <div>
      {authOnLoading ? (
        <div className="container max-w-xl mx-auto">
          <CenteredLoader />
        </div>
      ) : (
        <>
          <div className="max-w-3xl mx-auto mb-[-20px]">
            <TopBar navigateBack={onNavigationBack} />
          </div>
          <div className="md:container max-w-xl mx-auto">
            <WizardContainer
              title={t("SingleSignOn.View.Title")}
              subtitle={
                isSignUpForm
                  ? t("SingleSignOn.View.SignUp.SubTitle")
                  : t("SingleSignOn.View.Login.SubTitle")
              }
              isTitleCentered={false}
              currentPage={undefined}
            >
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={getValidationSchema(t, {
                  email: true,
                })}
                onSubmit={(values, actions) =>
                  onSubmit(
                    values.email,
                    actions.validateForm,
                    actions.setFieldError,
                  )
                }
              >
                {({
                  handleSubmit,
                  touched,
                  handleBlur,
                  handleChange,
                  values,
                  errors,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box className="md:p-3 md:bg-white shadow-xl rounded-lg">
                      <TextField
                        error={
                          isInvalidEmail ||
                          Boolean(touched.email && errors.email)
                        }
                        fullWidth
                        label={t("RegisterView.SignUp.Email")}
                        margin="normal"
                        name="email"
                        id="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="standard"
                        inputProps={{ autoFocus: true }}
                        className="mb-1"
                      />
                      <ErrorMessage
                        data-testid="sso-validation-error"
                        name="email"
                        component="div"
                        className="text-red-500 text-sm mb-8 mt-0"
                      />
                      {!isSmallScreen && (
                        <hr className="h-px bg-gray-200 border-0 mt-8" />
                      )}
                      <div className="flex justify-center items-center md:px-4 py-8 md:py-4">
                        <Button
                          className="font-medium leading-6 tracking-[1.25px] w-full"
                          type="submit"
                          disabled={isLoading}
                        >
                          {t("SingleSignOn.View.ButtonText")}
                        </Button>
                      </div>
                      {isLoading && <Loader />}
                    </Box>
                  </form>
                )}
              </Formik>
            </WizardContainer>
          </div>
        </>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  signUpExternalUserCodeFlow: (idToken, provider) =>
    dispatch(signUpExternalUserCodeFlow(idToken, provider)),
});

const mapStateToProps = (state) => state;
export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(SingleSignOnEmailView),
);
