import { connect } from "react-redux";
import clsx from "clsx";
import {
  Box,
  Container,
  Grid, // Link,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Button from "@openup/shared/components/Button/Button";
import { makeStyles } from "@mui/styles";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import Page from "@src/components/Page";
import PropTypes from "prop-types";
import Logo from "@src/components/Logo";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LanguageSelector from "@src/components/LanguageSelector";
import { ReactComponent as AstronautSVG } from "@src/resources/images/astronaut.svg";
import { ReactComponent as AnalyseSVG } from "@src/resources/images/image_analyse.svg";
import { ReactComponent as WomanSVG } from "@src/resources/images/woman_innovation.svg";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { logout } from "@src/actions/userActions";

const useStyles = makeStyles((theme) => ({
  logoCenter: {
    [theme.breakpoints.down("sm")]: {
      top: 40,
      position: "absolute",
      height: 20,
      width: 100.23,
    },
    [theme.breakpoints.up("sm")]: {
      top: 80,
      position: "absolute",
      height: 28,
      width: 150.34,
    },
  },
  heading: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "48px",
      letterSpacing: 0.25,
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(2),
    },
  },
  headingResults: {
    [theme.breakpoints.down("sm")]: {
      width: 328,
    },
  },
  imgAstronaut: {
    margin: "auto",
    display: "block",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      width: 312,
      height: 188,
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(6),
    },
    [theme.breakpoints.up("sm")]: {
      width: "360px",
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
  },
  imgAnalyse: {
    margin: "auto",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      width: 312,
      height: 188,
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
  },
  imageWoman: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(4),
      width: 114,
      height: 188,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
  },
  bottomMessage: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(-0.625),
    },
  },
  cultureDropdown: {
    [theme.breakpoints.down("sm")]: {
      top: 24,
      position: "absolute",
      height: 48,
      width: 76,
      left: 268,
      "&:hover": {
        backgroundColor: theme.colors.primarySkin,
      },
    },
    [theme.breakpoints.up("sm")]: {
      top: 68,
      position: "relative",
      height: 48,
      width: 76,
      left: 330,
    },
  },
  sliderStyle: {
    "@media (max-width: 599px)": {
      marginTop: theme.spacing(5),
    },
    "padding-top": theme.spacing(0),
    "padding-bottom": theme.spacing(0),
    "& .slick-next": {
      width: "100%",
      height: "100%",
      "background-color": "transparent !important",
      right: 0,
    },
    "& .slick-prev": {
      "background-color": "transparent !important",
    },
    "& .slick-dots": {
      display: "flex !important",
      "justify-content": "center",
      margin: theme.spacing(0),
      bottom: -16,
      "list-style-type": "none",
    },
    "& .slick-dots li": {
      margin: "0 0.15rem",
      width: "15px !important",
    },
    "& .slick-dots li button": {
      width: 10,
      height: 10,
      padding: theme.spacing(0),
      border: "none",
      "border-radius": "100% !important",
      "text-indent": "-9999px",
      "background-color": "lightgrey",
    },
    "& .slick-dots li.slick-active button": {
      "background-color": "#48B4FF",
      width: "10px !important",
      height: 10,
    },
    "& .slick-next:before": {
      color: "transparent",
    },
  },
  slideItemContainer: {
    textAlign: "center",
  },
  containerStyle: {
    "@media (max-width: 599px)": {
      paddingTop: theme.spacing(3.75),
    },
    "@media (min-width: 600px)": {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(0),
    },
  },
}));

const SlideItem = ({ text, textClassName, children }) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));
  return (
    <div className={classes.slideItemContainer}>
      <Typography
        variant={isSmallScreen ? "h3" : "h1"}
        className={clsx(classes.heading, textClassName || "")}
      >
        {text}
      </Typography>
      {children}
    </div>
  );
};

const HomeView = ({ logoutUser }) => {
  const { t, pathT } = useTranslatedNavigate();
  const classes = useStyles();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));

  const sliderSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    touchMove: true,
  };

  const handleLoginClick = () => {
    logoutUser();
    navigate(pathT("route.login"));
  };

  return (
    <Page className={classes.root} title={t("HomeView.Title")}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Grid container direction="column" alignItems="center" item>
          <Box display="flex" alignItems="center" justifyContent="center">
            <LanguageSelector className={classes.cultureDropdown} />
            <Logo className={classes.logoCenter} />
          </Box>
          <Container maxWidth="sm" className={classes.containerStyle}>
            <Box>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Slider {...sliderSettings} className={classes.sliderStyle}>
                <SlideItem text={t("HomeView.card1")}>
                  <AstronautSVG className={classes.imgAstronaut} />
                </SlideItem>
                <SlideItem
                  text={t("HomeView.card2")}
                  textClassName={classes.headingResults}
                >
                  {isSmallScreen && (
                    <AnalyseSVG className={classes.imgAnalyse} />
                  )}
                  {!isSmallScreen && (
                    <AnalyseSVG className={classes.imgAnalyse} />
                  )}
                </SlideItem>
                <SlideItem text={t("HomeView.card3")}>
                  {isSmallScreen && <WomanSVG className={classes.imageWoman} />}
                  {!isSmallScreen && (
                    <WomanSVG className={classes.imageWoman} />
                  )}
                </SlideItem>
              </Slider>
            </Box>
          </Container>
          <Button
            onClick={() => navigate(pathT("route.register"))}
            className="font-display font-medium my-8 w-9/12 md:w-fit md:px-12"
          >
            {t("HomeView.GetStarted")}
          </Button>
          <Typography
            color="textSecondary"
            variant="body2"
            className={classes.bottomMessage}
          >
            {t("HomeView.AlreadySignedUp")}{" "}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <span
              // eslint-disable-next-line react/no-unknown-property
              underline="always"
              // eslint-disable-next-line react/no-unknown-property
              component={RouterLink}
              onClick={handleLoginClick}
              style={{ cursor: "pointer" }}
            >
              <b>
                <u>{t("HomeView.Login")}</u>
              </b>
            </span>
          </Typography>
        </Grid>
      </Box>
    </Page>
  );
};

// export default HomeView;
HomeView.propTypes = {
  logoutUser: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => {
    dispatch(logout());
  },
});

export default connect(null, mapDispatchToProps)(HomeView);
