import { Link as RouterLink } from "react-router-dom";
import { ListItem, Typography } from "@mui/material";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import clsx from "clsx";

const ThemeMenuListItem = ({
  themeItem,
  listItemClass,
  typographyClass,
  subMenuClass,
  handleClose,
}) => {
  const { t, pathT } = useTranslatedNavigate();
  return (
    <RouterLink
      key={themeItem.key}
      to={pathT("route.themes", { key: t(`route.ThemeKey.${themeItem.key}`) })}
      onClick={handleClose}
    >
      <ListItem button className={listItemClass}>
        <Typography
          variant="body2"
          className={clsx(typographyClass, subMenuClass)}
        >
          {t(`SharedStrings.${themeItem.key}`)}
        </Typography>
      </ListItem>
    </RouterLink>
  );
};

export default ThemeMenuListItem;
