import { useEffect, useState } from "react";
import { useParams, useOutletContext } from "react-router-dom";
import Language from "@src/views/booking/components/Language";
import {
  usePsychologistTagsQuery,
  useSchedulerReschedulerConsult,
} from "@src/queries/booking";
import TimeSlotPicker from "@src/views/booking/components/TimeSlotPicker";
import BookingConfirmation from "@src/views/booking/components/BookingConfirmation";
import { STATUS, TAG_TYPES } from "@src/utils/constants";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import {
  filterTagsOfType,
  getConsultType,
  isPsychologistInactive,
} from "@src/utils/helpers";
import { useConsultQuery } from "@src/queries/consults";

const STEPS = {
  LANGUAGE: "route.booking.language",
  TIMESLOTS: "route.booking.timeslots",
  CONFIRMATION: "route.booking.confirmation",
};

const RescheduleFlow = () => {
  const { consultId } = useParams();
  const { pathT } = useTranslatedNavigate();
  const {
    step,
    nextStep,
    navigateToUrl,
    addStepToSkip,
    setPreventGoingBack,
    setupFlowSteps,
    isFlowSetup,
  } = useOutletContext();

  const [language, setLanguage] = useState();
  const [selectedDateTime, setSelectedDateTime] = useState();
  const [sessionType, setSessionType] = useState();
  const [consult, setConsult] = useState();
  const [presetLanguages, setPresetLanguages] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: consultToReschedule } = useConsultQuery(
    consultId,
    STATUS.SCHEDULED,
    null,
  );
  const { data: psychologistTags } = usePsychologistTagsQuery(
    consultToReschedule?.employeeId,
  );

  const {
    rescheduleWithSamePsychologist: {
      mutateAsync: rescheduleWithSamePsychologist,
    },
    rescheduleWithNewPsychologist: {
      mutateAsync: rescheduleWithNewPsychologist,
    },
  } = useSchedulerReschedulerConsult();

  useEffect(() => {
    setupFlowSteps(STEPS);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  useEffect(() => {
    if (step === STEPS.CONFIRMATION) {
      setPreventGoingBack(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [step]);

  useEffect(() => {
    if (!consultToReschedule) {
      navigateToUrl(pathT("route.booking"));
      return;
    }

    // Old sessions may not have session type and/or language assigned
    setSessionType(getConsultType(consultToReschedule));
    setConsult(consultToReschedule);
    setPresetLanguages(
      filterTagsOfType(psychologistTags, TAG_TYPES.PSYCHOLOGIST_LANGUAGE),
    );

    if (
      !isPsychologistInactive(psychologistTags) &&
      !!consultToReschedule.consultLanguage
    ) {
      setLanguage(consultToReschedule.consultLanguage);
      addStepToSkip(STEPS.LANGUAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [consultToReschedule, psychologistTags, isFlowSetup]);

  const rescheduleConsult = () => {
    setIsSubmitting(true);
    if (!isPsychologistInactive(psychologistTags)) {
      return rescheduleWithSamePsychologist({
        consultId: consultToReschedule.id,
        time: selectedDateTime.startTime,
        timezone: selectedDateTime.timezone,
      });
    }

    const tags = [
      {
        tagName: sessionType,
        tagType: TAG_TYPES.SESSION_TYPE,
      },
      {
        tagName: language,
        tagType: TAG_TYPES.SESSION_LANGUAGE,
      },
    ];
    return rescheduleWithNewPsychologist({
      consultId: consultToReschedule.id,
      time: selectedDateTime.startTime,
      timezone: selectedDateTime.timezone,
      tags,
    });
  };

  const selectStep = (selectedStep) => {
    switch (selectedStep) {
      case STEPS.LANGUAGE:
        return (
          <Language
            moveToNextStep={nextStep}
            setLanguage={setLanguage}
            available={!isPsychologistInactive(psychologistTags)}
            firstname={consultToReschedule.employeeFullName.split(" ")[0]}
            sessionType={sessionType}
            presetLanguages={
              !isPsychologistInactive(psychologistTags) ? presetLanguages : null
            }
          />
        );
      case STEPS.TIMESLOTS:
        return (
          <TimeSlotPicker
            moveToNextStep={async () => {
              const rescheduledConsult = await rescheduleConsult();
              setConsult(rescheduledConsult);
              nextStep();
            }}
            selectedDateTime={selectedDateTime}
            setSelectedDateTime={setSelectedDateTime}
            sessionType={sessionType}
            language={language}
            psychologistId={
              !isPsychologistInactive(psychologistTags)
                ? consultToReschedule.employeeId
                : null
            }
            timezone={consultToReschedule.clientTimezone}
            isSubmitting={isSubmitting}
          />
        );

      case STEPS.CONFIRMATION:
        return <BookingConfirmation consult={consult} />;

      default:
        return null;
    }
  };

  if (consultToReschedule) {
    return selectStep(step);
  }

  return null;
};

export default RescheduleFlow;
