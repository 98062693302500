import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useLocation, Navigate } from "react-router-dom";

import { refreshAccessToken } from "@src/actions/userActions";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { timeSnackbar } from "@src/utils/constants";
import { withSnackbar } from "@src/components/SnackBarComponent";
import authService from "@src/services/auth.service";
import { PureComponent } from "react";

class LocalizationRedirectView extends PureComponent {
  constructor() {
    super();
    this.state = {
      finalPath: "",
    };
  }

  async componentDidMount() {
    // eslint-disable-next-line no-shadow
    const { refreshAccessToken, snackbarShowMessage, location, pathT, t } =
      this.props;

    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get("token");
    const email = urlParams.get("email")?.replace(/ /g, "+");
    const state = urlParams.get("state");

    const pathKey = state ? JSON.parse(atob(state)) : state;

    let finalPath = "";
    if (pathKey && pathKey.length > 0) {
      finalPath = pathT(pathKey[0]);
      for (let i = 1; i < pathKey.length; i += 1) {
        finalPath = `${finalPath}/${t(pathKey[i])}`;
      }
    }

    if (token && email) {
      try {
        await refreshAccessToken(
          email.trim()?.replaceAll(" ", "+"),
          token.trim()?.replaceAll(" ", "+"),
        );
        if (pathKey) {
          this.setState(() => ({ finalPath }));
        } else {
          this.setState(() => ({ finalPath: pathT("route.account") }));
        }
      } catch (error) {
        snackbarShowMessage(error);
        setTimeout(() => {
          authService.logout();
          this.setState(() => ({ finalPath: pathT("route.login") }));
        }, timeSnackbar);
      }
    } else if (pathKey) {
      this.setState(() => ({ finalPath }));
    } else {
      this.setState(() => ({ finalPath: "/" }));
    }
  }

  render() {
    const { finalPath } = this.state;

    return finalPath ? <Navigate to={finalPath} /> : null;
  }
}

LocalizationRedirectView.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  navigate: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
  pathT: PropTypes.func.isRequired,
};
const WrappedLocalizationRedirectView = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathT, t } = useTranslatedNavigate();

  return (
    <LocalizationRedirectView
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      navigate={navigate}
      location={location}
      pathT={pathT}
      t={t}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  refreshAccessToken: (email, token) =>
    dispatch(refreshAccessToken(email, token)),
});

const mapStateToProps = (state) => state;
export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(WrappedLocalizationRedirectView),
);
