import { useContext, useState } from "react";
import {
  InputAdornment,
  IconButton,
  Box,
  Typography,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { SingleSignOnButton } from "@src/components/SingleSignOnButton";
import GoogleLoginRegisterButton from "@src/views/auth/GoogleLoginRegisterButton";
import { validateExternalSignUp } from "@src/utils/validationHelpers";
import PasswordStrengthMeter from "@src/components/PasswordStrengthMeter";
import AppleSignInSignUpButton from "@src/components/AppleSignInSignUpButton";
import MicrosoftSignInSignUpButton from "@src/components/MicrosoftSignInSignUpButton";
import { Formik } from "formik";
import { getValidationSchema } from "@src/views/auth/RegisterView/onboardingHelpers";
import authService from "@src/services/auth.service";
import { ApplicationStateContext } from "@src/utils/ApplicationStateContext";
import sessionStorageService from "@src/services/sessionStorage.service";
import WizardContainer from "@src/views/onboarding/components/WizardContainer";
import Button from "@openup/shared/components/Button/Button";
import { upperCase } from "lodash";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";

const useStyles = makeStyles(({ breakpoints, colors, spacing }) => ({
  content: {
    // padding: "0 0 230px 0",
    [breakpoints.up("sm")]: {
      padding: spacing(3),
    },
  },
  buttonSelected: {
    color: colors.primaryBlue,
    backgroundColor: "#F6FBFF",
    borderColor: colors.primaryBlue,
  },
  textFieldEmail: {
    marginTop: 0,
  },
  googleLoginButton: {
    borderWidth: "2px",
    borderColor: "lightgray",
    margin: "0 !important",
    // add hover styles
    "&:hover": {
      borderWidth: "2px",
      borderColor: "gray",
    },
  },
  singleSignOnButton: {
    borderWidth: "2px",
    borderColor: "lightgray",
    margin: "0 !important",
  },
}));

const CredentialsForm = ({ moveToNextStep }) => {
  const { t, pathT } = useTranslatedNavigate();
  const navigate = useNavigate();
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));
  const { setPassword } = useContext(ApplicationStateContext);
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  const onSubmit = (values) => {
    setPassword(values.password);
    authService.saveUserToStorage({
      email: values.email,
    });

    moveToNextStep();
  };

  return (
    <WizardContainer
      title={t("RegisterView.SignUp")}
      subtitle={t("RegisterView.SignUp.Subtitle")}
      isTitleCentered={false}
    >
      <Formik
        initialValues={{
          email: sessionStorageService.getUser()?.email || "",
          password: "",
        }}
        validationSchema={getValidationSchema(t, {
          email: true,
          password: true,
        })}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          touched,
          handleBlur,
          handleChange,
          values,
          isSubmitting,
          setFieldError,
          setFieldTouched,
          errors,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box className={classes.content}>
              <TextField
                className={classes.textFieldEmail}
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label={t("RegisterView.SignUp.Email")}
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="standard"
                inputProps={{ autoFocus: true }}
              />

              <TextField
                className={classes.textFieldPassword}
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label={t("RegisterView.SignUp.Password")}
                margin="normal"
                name="password"
                onBlur={handleBlur}
                value={values.password}
                variant="standard"
                type={showPassword ? "text" : "password"}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleShowPassword} size="large">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <PasswordStrengthMeter password={values.password} />

              <Button
                className="font-medium leading-6 tracking-[1.25px] w-full mt-6"
                disabled={isSubmitting}
                type="submit"
              >
                {t("RegisterView.SignUp")}
              </Button>

              {isSmallScreen ? (
                <Typography
                  variant="body2"
                  align="center"
                  className="my-3 font-semibold"
                >
                  {upperCase(t("RegisterView.Or"))}
                </Typography>
              ) : (
                <hr className="w-full h-px my-4 bg-gray-200 border-0" />
              )}

              <div className="grid gap-3 my-3">
                <GoogleLoginRegisterButton
                  className="px-6 py-4 m-0 bg-white border-2 hover:border-2 border-gray-300 hover:border-gray-400 hover:bg-gray-50 transition-all"
                  isSignUpForm
                  onValidate={() =>
                    validateExternalSignUp(
                      setFieldError,
                      setFieldTouched,
                      values.termsCheckbox,
                    )
                  }
                  onLoginCallback={moveToNextStep}
                />
                <AppleSignInSignUpButton
                  className="px-6 py-4 m-0 bg-white border-2 hover:border-2 border-gray-300 hover:border-gray-400 hover:bg-gray-50 transition-all"
                  isSignUpForm
                  buttonText={t("RegisterView.AppleRegister")}
                  onValidate={() =>
                    validateExternalSignUp(
                      setFieldError,
                      setFieldTouched,
                      values.termsCheckbox,
                    )
                  }
                  onLoginCallback={moveToNextStep}
                />
                <MicrosoftSignInSignUpButton
                  className="px-6 py-4 m-0 bg-white border-2 hover:border-2 border-gray-300 hover:border-gray-400 hover:bg-gray-50 transition-all"
                  isSignUpForm
                  buttonText={t("RegisterView.MicrosoftRegister")}
                  onValidate={() =>
                    validateExternalSignUp(
                      setFieldError,
                      setFieldTouched,
                      values.termsCheckbox,
                    )
                  }
                  onLoginCallback={moveToNextStep}
                />
                <SingleSignOnButton
                  fullWidth
                  className="px-6 py-4 m-0 bg-white border-2 hover:border-2 border-gray-300 hover:border-gray-400 hover:bg-gray-50 transition-all"
                  text={t("RegisterView.SingleSignOn")}
                  onClick={() =>
                    navigate(`${pathT("route.singleSignOn")}?IsSignUp=true`)
                  }
                />
              </div>

              <div className="flex text-sm text-gray-500 w-full justify-center mt-4">
                <p>{t("RegisterView.AlreadySignedUp")}</p>
                <p className="ml-1">
                  <Link
                    href={pathT("route.login")}
                    className="text-gray-500 font-medium underline"
                  >
                    {t("LoginView.Login")}
                  </Link>
                </p>
              </div>
            </Box>
          </form>
        )}
      </Formik>
    </WizardContainer>
  );
};

export default CredentialsForm;
