import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import backgroundImageRightTop from "@src/resources/backgrounds/neutral-top-right.svg";
import backgroundImageLeftBottom from "@src/resources/backgrounds/neutral-bottom-left.svg";
import colors from "@src/theme/colors";
import LayoutThemePage from "./LayoutThemePage";

const useStyles = makeStyles(() => ({
  themeRoot: {
    "@media (min-width: 1280px)": {
      background: `linear-gradient(to right, ${colors.secondarySkin}, ${colors.secondarySkin} 20%, ${colors.primarySkin} 20%, ${colors.primarySkin} 100%)`,
    },
  },
}));

const ThemePageMenuLayout = () => {
  const classes = useStyles();
  return (
    <ThemeProvider
      theme={(theme) =>
        createTheme({
          ...theme,
          typography: {
            ...theme.typography,
            h1: {
              ...theme.typography.h1,
              [theme.breakpoints.down("sm")]: {
                fontSize: "35px",
              },
            },
            h3: {
              ...theme.typography.h3,
              fontSize: "35px",
              [theme.breakpoints.down("sm")]: {
                fontSize: "25px",
              },
            },
            h6: {
              ...theme.typography.h6,
              fontSize: "16px",
              letterSpacing: "0.5px",
              opacity: 0.7,
            },
          },
        })
      }
    >
      <LayoutThemePage
        className={classes.themeRoot}
        backgroundColor={colors.primarySkin}
        bottomLeftImageUrl={backgroundImageLeftBottom}
        topRightImageUrl={backgroundImageRightTop}
      />
    </ThemeProvider>
  );
};

export default ThemePageMenuLayout;
