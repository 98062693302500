import moment from "moment-timezone";
import { upperFirst } from "lodash";
import { getClientTimezone } from "@src/utils/timezone";
import { spaceTypes } from "@src/utils/constants";
import { addMinutes, parseISO } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

// Dividing by 1 removes trailing 0's in case of integer values (eg. 1.0 -> 1)
export const formatScore = (score) =>
  score !== undefined ? score.toPrecision(2) / 1 : undefined;

export const formatSessionType = (consultType) =>
  consultType && upperFirst(consultType);

export const formatSpaceType = (spaceTheme) =>
  spaceTheme && encodeURIComponent(upperFirst(spaceTheme));

export const spaceLengthByType = (spaceTheme) => {
  switch (spaceTheme) {
    case spaceTypes.GROUPSESSION:
      return 60;
    case spaceTypes.MASTERCLASS:
      return 45;
    case spaceTypes.MINDFULNESS:
      return 30;
    default:
      return 60;
  }
};

export const formatConsultHour = (consult) =>
  moment(consult.startedAt)
    .utc()
    .tz(consult.clientTimezone ? consult.clientTimezone : getClientTimezone())
    .format("HH:mm");

export const formatTime = (time) =>
  time?.replaceAll("-", "").replaceAll(":", "");

export const formatSpaceTimeToGoogleCalendarTime = (time, minutes = 0) =>
  // minutes to be added to the time
  formatInTimeZone(
    addMinutes(parseISO(time), minutes),
    "UTC",
    "yyyyMMdd'T'HHmmss'Z'",
  );

export const decodeHtmlSpecialCharacters = (html) => {
  const txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

export const moveForwardWeeks = (weeks) =>
  moment().utc().startOf("isoWeek").add(weeks, "weeks");

export const moveBackwardWeeks = (weeks) =>
  moment().utc().endOf("isoWeek").add(weeks, "weeks");

export const formatWeek = (time) => time.format("D MMM");

export const formatWeekWithYear = (time) => time.format("D MMM YYYY");

export const getYearTime = (time) => moment(time).year();

export const formatDateWithoutMonth = (time) => moment(time).utc().format("D");

export const formatConsultDateExtendedWritten = (consult) =>
  moment(consult.startedAt)
    .utc()
    .tz(consult.clientTimezone ? consult.clientTimezone : getClientTimezone())
    .format("MMMM D, YYYY");

export const getWeekStartQueryKey = (dateTime) =>
  moment(dateTime).utc().startOf("isoWeek").startOf("day").toISOString();

export const formatConsultDate = ({ startAt, timezone }) =>
  moment(startAt)
    .utc()
    .tz(timezone || getClientTimezone())
    .format("DD-MM-YY");

export const formatSessionDateWithTimezone = (session, t, i18n) => {
  let timezone;
  if (session.timezone) {
    timezone = i18n.exists(`SharedStrings.Timezone.${session.timezone}`)
      ? t(`SharedStrings.Timezone.${session.timezone}`)
      : session.timezone;
  } else {
    timezone = getClientTimezone();
  }
  return `${formatInTimeZone(
    parseISO(session.startAt),
    session.timezone ?? getClientTimezone(),
    "dd-MM-yy HH:mm",
  )} ${timezone}`;
};

export const getReadableTimeFromSeconds = (seconds) => {
  if (seconds <= 0) {
    return "";
  }

  if (seconds > 3600) {
    return `${Math.floor(seconds / 3600)} hours`;
  }

  if (seconds > 120) {
    return `${Math.floor(seconds / 60)} minutes`;
  }

  return `${seconds} seconds`;
};
