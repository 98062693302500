import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";

const useStyles = makeStyles((theme) => ({
  switchLabel: {
    fontWeight: 400,
  },
  switch: {
    "& .MuiIconButton-label": {
      display: "flex",
    },
  },
  switchChecked: {
    color: `${theme.palette.common.white}!important`,
  },
  switchTrack: {
    backgroundColor: `${theme.colors.primaryBlue}!important`,
    opacity: "1!important",
  },
  switchThumb: {
    border: `1px solid ${theme.colors.primaryBlue}`,
  },
}));

const ThemeFocusItem = ({ id, checked, onChange, disabled }) => {
  const classes = useStyles();

  const { t } = useTranslatedNavigate();

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Typography className={classes.switchLabel}>
        {t(`SharedStrings.${id}`)}
      </Typography>
      <Switch
        color="secondary"
        classes={{
          switchBase: clsx(classes.switch, checked && classes.switchChecked),
          thumb: clsx(checked && classes.switchThumb),
          track: clsx(checked && classes.switchTrack),
          checked: clsx(checked && classes.switchChecked),
        }}
        checked={checked}
        onChange={onChange}
        name={id}
        disabled={disabled}
      />
    </Box>
  );
};

export default ThemeFocusItem;
