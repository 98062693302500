import Layout from "@src/layouts/Layout";
import { ThemeProvider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import medicalTheme from "@src/theme/medicalTheme";
import theme from "@src/theme";

const useStyles = makeStyles(({ colors }) => ({
  dashboardRoot: {
    "@media (min-width: 1280px)": {
      background: `linear-gradient(to right, ${colors.trueWhite}, ${colors.trueWhite} 20%, ${colors.primaryOffWhite} 20%, ${colors.primaryOffWhite} 100%)`,
    },
  },
}));

const DashboardLayout = ({ showFooter = true, isMedical = false }) => {
  const classes = useStyles();
  const currentTheme = isMedical ? medicalTheme : theme;

  return (
    <ThemeProvider theme={currentTheme}>
      <Layout
        className={classes.dashboardRoot}
        backgroundColor={theme.colors.primaryOffWhite}
        showFooter={showFooter}
        isMedical={isMedical}
      />
    </ThemeProvider>
  );
};

export default DashboardLayout;
