import { Box, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { formatSessionType } from "@src/utils/formatting";
import { getConsultType } from "@src/utils/helpers";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { Icon } from "@src/components/Icon";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import { IconSessionLanguage } from "@src/components/IconSessionLanguage";
import { IConsultType } from "@src/utils/constants";

const useStyle = makeStyles((theme) => ({
  detailsPaper: {
    width: "110%",
    marginBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(3, 2.5),
  },
  typography: {
    marginBottom: theme.spacing(2),
  },
  box: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    gap: theme.spacing(1.75),
  },
  flag: {
    width: 20,
    height: 20,
  },
}));

const SessionDetailsPaper = ({ consult }) => {
  const classes = useStyle();
  const { t } = useTranslatedNavigate();

  return (
    <Paper className={`${classes.detailsPaper} ${classes.paper}`}>
      <Typography className={classes.typography} variant="h5">
        {t("MySessionsView.Notes.Details.Title")}
      </Typography>
      <div className="flex mb-6 items-center text-indigo-800  gap-4">
        <Icon iconKey={getConsultType(consult) as IConsultType} />
        <p className="text-sm opacity-70">
          {t("MySessionsView.Notes.Details.SessionType", {
            sessionType: formatSessionType(getConsultType(consult)),
          })}
        </p>
      </div>
      <Box className={classes.box}>
        <Icon iconKey={consult.themeKey} />
        <Typography variant="body2">
          {t(`SharedStrings.${consult.themeKey}`)}
        </Typography>
      </Box>
      <Box className={classes.box}>
        <PsychologyOutlinedIcon />
        <Typography variant="body2">{consult.employeeFullName}</Typography>
      </Box>
      <Box className={classes.box}>
        <IconSessionLanguage
          sessionLanguage={consult.consultLanguage}
          className={classes.flag}
        />
        <Typography variant="body2">
          {t(`SessionLanguage.${consult.consultLanguage}`)}
        </Typography>
      </Box>
    </Paper>
  );
};

export default SessionDetailsPaper;
