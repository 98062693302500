import useMediaQuery from "@mui/material/useMediaQuery";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import Page from "@src/components/Page";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ExpandableTypography from "@src/components/ExpandableText";
import { useQuerySanityTheme } from "@src/queries/themes";
import { displayLogic } from "@src/utils/constants";
import { makeStyles } from "@mui/styles";
import { formatScore } from "@src/utils/formatting";
import HoverComponentThemeView from "./components/HoverComponentThemeView";

const useStyle = makeStyles((theme) => ({
  pageTheme: {
    lineHeight: "48px",
    letterSpacing: "0.25px",
  },
  learningGoal: {
    fontWeight: "bold",
    opacity: 0.7,
    letterSpacing: 0.1,
    marginBottom: theme.spacing(2),
  },
  checkMark: {
    width: 24,
    height: 24,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 2,
    paddingRight: 4,
    backgroundColor: "white",
    borderRadius: 100,
    marginRight: theme.spacing(2),
  },
}));

const ThemeViewOverview = ({
  themeKey,
  lastCheckin,
  firstThreeWorstQuestions,
}) => {
  const classes = useStyle();
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));
  const { t } = useTranslatedNavigate();

  const { data: sanityThemeContent } = useQuerySanityTheme(themeKey);

  const { category } =
    displayLogic[lastCheckin?.isTrackedTheme ? lastCheckin.category : 0];

  return (
    <Page
      id="ThemeViewOverview"
      title={t("ThemeViewOverview.PageTitle", {
        themeName: t(`SharedStrings.${themeKey}`),
      })}
    >
      <Typography variant="h2" className={classes.pageTheme}>
        {t(`ThemeViewOverview.${themeKey}.PageHeader.${category}`)}
      </Typography>
      <ExpandableTypography
        text={t(`ThemeViewOverview.${themeKey}.MainFeedbackText.${category}`, {
          key: lastCheckin?.score ? formatScore(lastCheckin?.score) : 5,
        })}
        variant={isSmallScreen ? "h6" : "body1"}
      />
      {sanityThemeContent?.translated_content?.learning_goals && (
        <Box marginTop={3}>
          {sanityThemeContent.translated_content?.learning_goals.map((goal) => (
            <Typography
              key={goal}
              variant="subtitle1"
              color="primary"
              className={classes.learningGoal}
            >
              <div className={classes.checkMark}>✓</div>
              {goal}
            </Typography>
          ))}
        </Box>
      )}
      <HoverComponentThemeView
        themeKey={themeKey}
        category={category}
        lastCheckin={lastCheckin}
        firstThreeWorstQuestions={firstThreeWorstQuestions}
      />
    </Page>
  );
};

export default ThemeViewOverview;
