import BasicLayout from "@src/layouts/BasicLayout";
import type { FC } from "react";

interface WideLayoutProps {
  isHeaderVisible?: boolean;
  isFooterVisible?: boolean;
}

const WideLayout: FC<WideLayoutProps> = ({
  isHeaderVisible = true,
  isFooterVisible = true,
}) => {
  return (
    <BasicLayout
      className="bg-orange-100"
      isHeaderVisible={isHeaderVisible}
      isFooterVisible={isFooterVisible}
    />
  );
};

export default WideLayout;
